<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarCase />
    </template>
    <template v-slot:content>

    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarCase from '@/viewsNew/SidebarCase';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    Layout,
    SidebarCase
  },
  watch: {
    getSearches: {
      deep: true,
      immediate: true,
      handler () {
        this.setLoaderBig(true);
        if (this.getSearches.length > 0) {
          this.$router.push({ path: `/case/${this.getCurrentCase.oid}/${this.getSearches[0].rid}` });
        } else {
          this.$router.push({ path: `/new-search/${this.getCurrentCase.oid}` });
        }
        this.setLoaderBig(false);
      }
    }
  },
  computed: {
    ...mapGetters(['loaderBig', 'getCurrentCase', 'getSearches'])
  },
  methods: {
    ...mapActions(['setLoaderBig'])
  }
};
</script>
